<template>
  <div id="app">
    <Navbar />
    <notifications group="notification" position="top center" classes="notification-style" />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar
  },
}
</script>

<style lang="scss">
@import url("https://use.typekit.net/ans5yzs.css");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

:root {
  font-size: 10px;
  --aurizor-blue: #0D46D6;
  --black: #00091A;
  --red-btn-text: #A62A2A;
  --red-btn-bg: #FFEFEF;
  --border: #E3E3E3;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'proxima-nova', sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s ease;
}

h1, h2, h3, h4, h5, h6, p {
  color: var(--black);
}

h4 {
  font-size: 35px;
  font-weight: normal;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 30px;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.container-small {
  width: 900px;
  margin: 0 auto;
}

.sub-title {
  font-size: 18px;
  line-height: 22px;
}

.sub-title-two {
  font-size: 15px;
  line-height: 18px;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .input-error {
    font-size: 14px;
    color: var(--red-btn-text) !important;
    font-weight: 500;
    margin-top: 3px;
  }

  label {
    font-size: 16px;
    margin-bottom: 7px;
    font-weight: 500;
  }

  input, textarea {
    padding: 1rem 1.2rem .9rem;
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--black);
    vertical-align: middle;
    border: 1.5px solid #e4e4e4;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    background: #fff;
    line-height: 24px;
    outline: none;
    resize: none;

    &:active, &:focus {
      border-color: var(--aurizor-blue);
      outline: none;
    }
  }
}

.primary-btn {
  color: #fff;
  background: var(--aurizor-blue);

  &:hover {
    background: darken(#0D46D6, 10%);
  }
}

.btn-danger {
  color: var(--red-btn-text);
  background: var(--red-btn-bg);

  &:hover {
    background: darken(#FFEFEF, 5%);
  }
}

.disable-click {
  pointer-events: none;
  cursor: default;
}

// Button loader
.loader {
  border: 3px solid #fff;
  border-radius: 50%;
  border-top: 3px solid var(--aurizor-blue);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.ld-bg {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 5px;
  pointer-events: none;
}
.btn-done-status {
  width: 100%;
  height: 100%;
  background: rgb(51, 52, 53);
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  border-radius: 5px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  cursor: default;
  transition: all 0.3s ease;
}
.makeVisible {
  visibility: visible !important;
  opacity: 1 !important;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Toast
.toasted .primary, .toasted.toasted-primary {
  border-radius: 4px !important;
  background-color: #000;
  padding: 12px 24px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  box-shadow: 0 7px 10px rgb(0 0 0 / 12%) !important;
}
</style>
