import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    showNavBar: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_SHOW_NAVBAR(state, status) {
      state.showNavBar = status;
    },
  },
  actions: {
    async SET_USER(state) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/user`,
          {
            withCredentials: true,
          }
        );
        const success = response.data.success;
        if (success) {
          state.commit("SET_USER", response.data.user);
        } else {
          state.commit("SET_USER", {});
        }
      } catch (err) {
        Vue.notify({
          group: "alert",
          type: "error",
          title: "Something went wrong",
        });
      }
    },
    SET_SHOW_NAVBAR(state, showNavBar) {
      state.commit("SET_SHOW_NAVBAR", showNavBar);
    },
  },
  modules: {},
  getters: {
    GET_USER: (state) => state.user,
    SHOW_NAV_BAR: (state) => state.showNavBar,
  },
});
