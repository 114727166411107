<template>
  <div :class="$style.navBar" v-if="showNavBar">
    <nav class="container">
      <div :class="$style.left">
        <router-link to="/" tag="a" :class="$style.logo">
          <svg
            width="28"
            height="35"
            viewBox="0 0 28 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M27.1553 0H15.5173V35H27.1553V0Z" fill="#0D46D6" />
            <path d="M11.638 0H0V19.4444H11.638V0Z" fill="#0D46D6" />
            <path d="M11.638 23.3333H0V35H11.638V23.3333Z" fill="#0D46D6" />
          </svg>
        </router-link>
      </div>
      <div :class="$style.right">
        <div>
          <router-link
            to="/site/create"
            tag="button"
            class="primary-btn"
            :class="$style.newWebsite"
            >New Website</router-link
          >
        </div>
        <div :class="$style.userDp">
          <p>{{ userDPName }}</p>
        </div>
        <div :class="$style.logout" class="btn-danger" @click="logout">
          Logout
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.51428 20H4.51428C3.40971 20 2.51428 19.1046 2.51428 18V6C2.51428 4.89543 3.40971 4 4.51428 4H8.51428V6H4.51428V18H8.51428V20Z"
              fill="currentColor"
            />
            <path
              d="M13.8418 17.385L15.262 15.9768L11.3428 12.0242L20.4857 12.0242C21.038 12.0242 21.4857 11.5765 21.4857 11.0242C21.4857 10.4719 21.038 10.0242 20.4857 10.0242L11.3236 10.0242L15.304 6.0774L13.8958 4.6572L7.5049 10.9941L13.8418 17.385Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "@/axios";

export default {
  name: "Navbar",
  computed: {
    ...mapGetters({ user: "GET_USER", showNavBar: "SHOW_NAV_BAR" }),
    userDPName() {
      if (this.user.displayName) return this.user.displayName.charAt(0);
      return "";
    },
  },
  methods: {
    async logout() {
      try {
        await axios.get(`${process.env.VUE_APP_API_URL}/auth/logout`);
        await this.$router.push("/login");
      } catch (err) {
        this.$toasted.error("Something went wrong");
      }
    },
  },
};
</script>

<style lang="scss" module>
.nav-bar {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
}
nav {
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    .logo {
      cursor: pointer;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .new-website {
      height: 3.6rem;
      font-size: 1.6rem;
      padding: 0 1.6rem;
    }

    .user-dp {
      background: #e77e1e;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 3.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 48px;

      p {
        font-size: 1.6rem;
        color: #fff;
        font-weight: 500;
      }
    }

    .logout {
      margin-left: 8px;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 8px 16px;
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s ease;

      svg {
        height: 20px;
        width: auto;
        display: block;
        margin-left: 4px;
        transform: rotate(180deg);
      }
    }
  }
}
</style>
