import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Notifications from "vue-notification";
import Vuelidate from 'vuelidate';
import Toasted from 'vue-toasted';

Vue.config.productionTip = false;

Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  position: 'top-center',
  duration: 4000,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
