import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "DashHome",
    component: () => import("../views/DashHome"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Authentication"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/Authentication"),
  },
  {
    path: "/site/create",
    name: "NewSite",
    component: () => import("../views/NewWebsite"),
  },
  {
    path: "/site/:siteId",
    name: "Site",
    component: () => import("../views/Site"),
  },
  {
    path: "/site/:siteId/:action",
    name: "SiteAction",
    component: () => import("../views/Site"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('SET_USER');
  const user = store.state.user;
  if (to.name === 'Login' || to.name === 'Signup') {
    if (Object.keys(user).length !== 0 && user.constructor === Object) {
      next('/');
    } else {
      next();
    }
  } else {
    if (Object.keys(user).length === 0 && user.constructor === Object) {
      next('/login')
    } else {
      await store.dispatch('SET_SHOW_NAVBAR', true);
      next();
    }
  }
});

export default router;
